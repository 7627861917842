define("commander/helpers/search-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.searchWindow = searchWindow;
  /**
   * Return searchWindow time interval for a trip if matching given anchor.
   * @param {Array} params params[0] = trip, params[1] = anchor ('pick'/'drop')
   */
  function searchWindow(params) {
    const trip = params[0];
    const anchor = ['pick', 'drop'].includes(params[1]) ? params[1] : 'pick';
    function addSeconds(date, timeInSeconds) {
      return moment(date).add(timeInSeconds, 'seconds').format('LT');
    }
    if (trip.anchor === anchor) {
      const requestedTime = trip.requestedTime;
      let pickStart = '';
      let pickEnd = '';
      const searchWindow = trip.searchWindow ? trip.searchWindow[anchor] : undefined;
      if (searchWindow) {
        pickStart = addSeconds(requestedTime, -searchWindow.start);
        pickEnd = addSeconds(requestedTime, +searchWindow.end);
      }
      return pickStart && pickEnd ? `(${pickStart} - ${pickEnd})` : '';
    }
    return '';
  }
  var _default = _exports.default = Ember.Helper.helper(searchWindow);
});